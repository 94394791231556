import React, { useContext, useState, useLayoutEffect, useEffect } from 'react'
import MenuListMulti from '../menuListMulti'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'


const DropdownNav = ({ pageContext, mainMenu, secondMenu, thirdMenu, showMoreLabel, showLessLabel, pageLoad, sideNavUseLoc, isArchive }) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  
  
  const initCat = () => {
    // return state.sideNavUse === 'n3' ? true : false
    return (state.sideNavUse === 'n3' || sideNavUseLoc === 'n3') ? true : false
  }
  const[catMenu, setCatMenu] = useState( () => initCat() )
  useEffect(() => {

    // (state.sideNavUse === 'n3' && !isArchive) ?
    // setCatMenu(true)
    // :
    // setCatMenu(false)

    ( (state.sideNavUse === 'n3' || sideNavUseLoc === 'n3') && !catMenu ) &&
    setCatMenu(true)
    
  }, [state.sideNavUse])

  useEffect(() => {
    (state.sideNavUse !== 'n3' || sideNavUseLoc !== 'n3') && setCatMenu(false)
  }, [])

  
  const[cssDelay, setcssDelay] = useState(false)
  useLayoutEffect(() => {
    setcssDelay(true)
    const timer = setTimeout(() => {
      setcssDelay(false)
    }, 444)
    return () => clearTimeout(timer)
  }, [catMenu])


  // console.log(state.sideNavUse)
  
  // BROWSER sessionStorage BACKUPING SIDENAVUSE - PREVENT LOST STATE AFTER PAGE REFRESH (F5 etc.)

  // useEffect(() => {

  // }, [])
  
  if(typeof sideNavUseLoc !== 'undefined') {
    if(typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined' && window.sessionStorage !== null ) {
      window.sessionStorage.setItem("sideNavUse", sideNavUseLoc)      
    }
  }
  else if (typeof sideNavUseLoc === 'undefined') {

    if( typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined' && window.sessionStorage !== null ) {

      const sessionStorageSideNav = window.sessionStorage.getItem("sideNavUse")

      if( typeof sessionStorageSideNav !== 'undefined' && sessionStorageSideNav !== '' ) {
        sideNavUseLoc = sessionStorageSideNav
      }
      
    }
    
  }
  

  // console.log(sideNavUseLoc)
  // console.log(state.sideNavUse)

  useEffect(() => {
    
    if(state.sideNavUse !== sideNavUseLoc) {
      dispatch({ type: 'SIDENAV_2USE', value: sideNavUseLoc })
    }

  }, [sideNavUseLoc, state.sideNavUse])
  // }, [sideNavUseLoc])

  
  return (
    <div 
      className={`side-bar-nav-bar ${isArchive ? 'isArchive' : ''}`}
    >

      <nav>

        <div
          // onClick={() => state.sideNavUse !== 'n1' ? dispatch({ type: 'SIDENAV_2USE', value: 'n1' }) : {} }
          // onKeyDown={() => state.sideNavUse !== 'n1' ? dispatch({ type: 'SIDENAV_2USE', value: 'n1' }) : {} }
          onClick={() => sideNavUseLoc !== 'n1' ? dispatch({ type: 'SIDENAV_2USE', value: 'n1' }) : {} }
          onKeyDown={() => sideNavUseLoc !== 'n1' ? dispatch({ type: 'SIDENAV_2USE', value: 'n1' }) : {} }
          tabIndex="0"
          role="button"
        >
          <MenuListMulti 
            pagePath={pageContext.pagePath} 
            pathMod={'absolute'} 
            list={mainMenu}
            pageLoad={pageLoad}
            sideNavUse={sideNavUseLoc === 'n1' ? true : false}
            navId={'n1'}
            showLessLabel={showLessLabel}
            showMoreLabel={showMoreLabel}
          />



        </div>

        <div className={`sidebarSeparator`} ></div>


        <div
          className={``}

          // onClick={() => state.sideNavUse !== 'n2' ? dispatch({ type: 'SIDENAV_2USE', value: 'n2' }) : {} }
          // onKeyDown={() => state.sideNavUse !== 'n2' ? dispatch({ type: 'SIDENAV_2USE', value: 'n2' }) : {} }
          onClick={() => sideNavUseLoc !== 'n2' ? dispatch({ type: 'SIDENAV_2USE', value: 'n2' }) : {} }
          onKeyDown={() => sideNavUseLoc !== 'n2' ? dispatch({ type: 'SIDENAV_2USE', value: 'n2' }) : {} }
          tabIndex="0"
          role="button"
        >
          <MenuListMulti 
            pagePath={pageContext.pagePath} 
            pathMod={'absolute'} 
            list={secondMenu}
            pageLoad={pageLoad}
            sideNavUse={sideNavUseLoc === 'n2' ? true : false}
            navId={'n2'}
            showLessLabel={showLessLabel}
            showMoreLabel={showMoreLabel}
          />
        </div>


        <div className={`sidebarSeparator`} ></div>

        <div
          className={`catMenuWrap`}
          style={
            // (pageLoad === true && state.sideNavUse !== 'n3') ? 
            (pageLoad === true && sideNavUseLoc !== 'n3') ? 
            {
              height: `${pageContext.currentLang !== 'de-DE' ? '172px' : '182px'}`,
              // transition: 'all 1ms !important'
            } :
            catMenu ?
            {
              height: `${cssDelay ? `${(182 + ( (thirdMenu.length - 5) * 36) )}px` : 'auto'}`,
            } :
            {
              // height: '172px',
              height: `${cssDelay ? `${(thirdMenu.length) * 36}px` : `${pageContext.currentLang !== 'de-DE' ? '172px' : '182px'}`}`,
              overflow: 'hidden',
            }
          }
          // onClick={() => !catMenu && setCatMenu(true)}
          // onKeyDown={() => !catMenu && setCatMenu(true)}


          // onClick={() => state.sideNavUse !== 'n3' ? dispatch({ type: 'SIDENAV_2USE', value: 'n3' }) : {} }
          // onKeyDown={() => state.sideNavUse !== 'n3' ? dispatch({ type: 'SIDENAV_2USE', value: 'n3' }) : {} }
          onClick={() => sideNavUseLoc !== 'n3' ? dispatch({ type: 'SIDENAV_2USE', value: 'n3' }) : {} }
          onKeyDown={() => sideNavUseLoc !== 'n3' ? dispatch({ type: 'SIDENAV_2USE', value: 'n3' }) : {} }
          tabIndex="0"
          role="button"
        >
          <MenuListMulti 
            pagePath={pageContext.pagePath} 
            pathMod={'absolute'} 
            list={thirdMenu}
            pageLoad={pageLoad}
            sideNavUse={sideNavUseLoc === 'n3' ? true : false}
            navId={'n3'}
            showLessLabel={showLessLabel}
            showMoreLabel={showMoreLabel}
          />
        </div>

        <button
          className={`catMenuButton sidebarButton ${catMenu ? cssDelay ? 'transition' : 'open' : cssDelay ? 'transition' : 'close'}`}
          onClick={() => setCatMenu(catMenu ? false : true)}
          onKeyDown={() => setCatMenu(catMenu ? false : true)}
          tabIndex="0"
        >
          {catMenu ? showLessLabel : cssDelay ? showLessLabel : showMoreLabel}
        </button>
        
      </nav>

    </div>
  )

}
export default DropdownNav