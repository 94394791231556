import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import DropdownNav from 'src/components/common/sidebar/dropdownNav'

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'

// import Filters from 'src/components/common/sidebar/filters'

import loadable from '@loadable/component'

const Filters = loadable(() => import('./filters'))


const Sidebar = ({ mobile, pageContext, mainMenu, secondMenu, thirdMenu, pageLoad, sideNavUseLoc, isArchive, archiveSidebar, location }) => {
  const state = useContext(GlobalStateContext)
  const graphqlResult = useStaticQuery(graphql`
    query SidebarQuery {
      labels: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "sidebar-labels"}}) {
        acfsidebarlabels {
          sidebarOpenButtonLabelSidebarLabels
          sidebarCloseButtonLabelSidebarLabels
          sidebarCloseFilterLabelSidebarLabels
          menuOpenSidebarLabels
          menuCloseSidebarLabels
          showMoreSidebarLabels
          showLessSidebarLabels
          atrributesMenuSidebarLabels
          resetFilterSidebarLabels
          resetFilterActionSidebarLabels
          noFiltersSidebarLabels
          attr1SidebarLabels
          attr2SidebarLabels
          attr3SidebarLabels
          attr4SidebarLabels
          attr5SidebarLabels
          attr6SidebarLabels
          attr7SidebarLabels
          attr8SidebarLabels
          attr9SidebarLabels
          attr10SidebarLabels
          attr11SidebarLabels
        }
      }
    }
  `)

  // const[sidebarOpen, setSidebarOpen] = useState(mobile ? false : true)
  const[sidebarOpen, setSidebarOpen] = useState(!mobile ? true : false)
  // const[filtersOpen, setFiltersOpen] = useState(false)
  const[filtersOpen, setFiltersOpen] = useState(state.activeFilters.length > 0 ? true : false)

  /*********************************************************************************************/
  /************ SPRAWDZIC, CZY TAK MOZNA (czy nie bedzie sie samo zamykac ludziom) ************/
  useEffect(() => {
    mobile ?
    setSidebarOpen(false)
    :
    setSidebarOpen(true)
  }, [mobile])
  /***************************************************************************************/
  /**************************************************************************************/

  // mainMenu = [...mainMenu, { label: archive.title, id: 'xyzyx', path: archive.slug, parentId: null, childItems: { nodes: [] }, order: 0, parentOf: [], special: 'archive' }]

  const labels = graphqlResult.labels.acfsidebarlabels
  const buttonOpenLabel = labels.sidebarOpenButtonLabelSidebarLabels
  const buttonCloseLabel = labels.sidebarCloseButtonLabelSidebarLabels
  const filterCloseLabel = labels.sidebarCloseFilterLabelSidebarLabels
  // const menuOpenLabel = labels.menuOpenSidebarLabels
  // const menuCloseLabel = labels.menuCloseSidebarLabels
  const showMoreLabel = labels.showMoreSidebarLabels
  const showLessLabel = labels.showLessSidebarLabels
  const attributesMenuLabel = labels.atrributesMenuSidebarLabels
  const resetFilterLabel = labels.resetFilterSidebarLabels
  const resetFilterActionLabel = labels.resetFilterActionSidebarLabels
  const noFiltersLabel = labels.noFiltersSidebarLabels
  const attr1 = labels.attr1SidebarLabels
  const attr2 = labels.attr2SidebarLabels
  const attr3 = labels.attr3SidebarLabels
  const attr4 = labels.attr4SidebarLabels
  const attr5 = labels.attr5SidebarLabels
  const attr6 = labels.attr6SidebarLabels
  const attr7 = labels.attr7SidebarLabels
  const attr8 = labels.attr8SidebarLabels
  const attr9 = labels.attr9SidebarLabels
  const attr10 = labels.attr10SidebarLabels
  const attr11 = labels.attr11SidebarLabels

  const orders = {
    [attr1]: '10',
    [attr2]: '20',
    [attr3]: '30',
    [attr4]: '40',
    [attr5]: '50',
    [attr6]: '60',
    [attr7]: '70',
    [attr8]: '80',
  }
  const archiveOrders = {
    // ARCHIWUM typ, przeznaczenie, kategoria
    [attr9]: '0',
    [attr10]: '3',
    [attr11]: '6',
  }


  const[cssDelay, setcssDelay] = useState(false)
  useLayoutEffect(() => {
    setcssDelay(true)
    const timer = setTimeout(() => {
      setcssDelay(false)
    }, 444);
    return () => clearTimeout(timer);
  }, [sidebarOpen])

  const[cssDelay2, setcssDelay2] = useState(false)
  useLayoutEffect(() => {
    setcssDelay2(true)
    const timer = setTimeout(() => {
      setcssDelay2(false)
    }, 555);
    return () => clearTimeout(timer);
  }, [])

  const[cssDelay3, setcssDelay3] = useState(false)
  useLayoutEffect(() => {
    setcssDelay3(true)
    const timer = setTimeout(() => {
      setcssDelay3(false)
    }, 444);
    return () => clearTimeout(timer);
  }, [state.activeFilters])

  const ifFilters = state.catFilters.common.filter((el) => {
    return el.count >= pageContext.counts
  })

  
  return (
    <>

      {mobile && 
        <div>
          <button
            className={`sidebarButton`}
            onClick={() => setSidebarOpen(sidebarOpen ? false : true)}
            onKeyDown={() => setSidebarOpen(sidebarOpen ? false : true)}
            tabIndex="0"
            style={{marginBottom:'40px'}}
          >
            {sidebarOpen ? buttonCloseLabel : buttonOpenLabel}

            {sidebarOpen ? 
            <span style={{
              fontSize:'14px',
            }}>▲</span> 
            : 
            <span style={{
              fontSize:'14px',
            }}>▼</span>
            }
          </button>
        </div>
      }


      {(sidebarOpen || !mobile) && <div 
        className={`sidebar ${mobile ? 'mobile' : 'desktop'} ${sidebarOpen ? 'sbOpen' : ''} ${cssDelay2 ? 'transition' : ''} ${pageLoad ? 'pageLoad' : ''}`}
        style={
          // sidebarOpen || cssDelay2 ?
          pageLoad === true ?
          {
            height: '0px',
            maxHeight: '0px',
            opacity: `${mobile ? '0' : '1'}`
          } :
          sidebarOpen ?
          {
            // height: `${cssDelay ? '300px' : 'fit-content'}`,
            height: `fit-content`,
            maxHeight: '4000px',
            // paddingTop: `${mobile ? '24px' : '0px'}`,
            // paddingBottom: '24px',
            marginBottom: '66px',
          } :
          {
            // height: `${(cssDelay && !pageLoad) ? 'fit-content' : '0px'}`,
            height: `${cssDelay ? 'fit-content' : '0px'}`,
            // maxHeight: `0px`,
            maxHeight: '0px',
            paddingTop: `0px`,
            paddingBottom: '0px',
            marginBottom: '0px',
          }
        }
      >

        <div>

          {buttonOpenLabel && <h5>{buttonOpenLabel}</h5>}

          {/* NAVIGATION COMPONENT, FOR ALL PAGES EXCEPT ARCHIVE */}
          {!archiveSidebar && 
            <>
              <DropdownNav 
                pageContext={pageContext} 
                mainMenu={mainMenu} 
                secondMenu={secondMenu} 
                thirdMenu={thirdMenu} 
                showMoreLabel={showMoreLabel} 
                showLessLabel={showLessLabel} 

                pageLoad={pageLoad}

                sideNavUseLoc={sideNavUseLoc}
                isArchive={isArchive}
              />
              <div className={`sidebarSeparator`} ></div>
            </>
          }

          {/* TAXONOMY FILTER COMPONENT, FOR ARCHIVE PAGE */}
          {archiveSidebar && 

            // <DropdownNav 
            //   pageContext={pageContext} 
            //   mainMenu={mainMenu} 
            //   secondMenu={secondMenu} 
            //   thirdMenu={thirdMenu} 
            //   showMoreLabel={showMoreLabel} 
            //   showLessLabel={showLessLabel} 

            //   pageLoad={pageLoad}

            //   sideNavUseLoc={sideNavUseLoc}
            //   isArchive={isArchive}
            // />

            <Filters 
              resetFilterLabel={resetFilterLabel} 
              resetFilterActionLabel={resetFilterActionLabel} 
              orders={archiveOrders} 
              filtersOpen={true}
              attributesMenuLabel={attributesMenuLabel}
              pageCounts={pageContext.counts}
              archiveNavMode={true}
              pageLoad={pageLoad}
              location={location}
            />

          }


          {/* {(attributesMenuLabel && ifFilters.length === 0) && <div className={`sidebarSeparator`} ></div>} */}          


          {/* {(attributesMenuLabel && ifFilters.length === 0) && <h5>{attributesMenuLabel}</h5>} */}


          {/* {ifFilters.length === 0 ? <button
            className={`sidebarButton filtersSecButton`}
            onClick={() => setFiltersOpen(filtersOpen ? false : true)}
            onKeyDown={() => setFiltersOpen(filtersOpen ? false : true)}
            tabIndex="0"
            style={
              (state.activeFilters.length === 0 && filtersOpen && !cssDelay3) ?
              {
                position: 'relative',
                top: '32px'
              } :
              {

              }
            }
          >
             {filtersOpen ? !cssDelay ? buttonCloseLabel : attributesMenuLabel : !cssDelay ? attributesMenuLabel : buttonCloseLabel}
          </button>
          :
          <span>{noFiltersLabel}</span>
          } */}


          <button
            className={`sidebarButton filtersSecButton ${ifFilters.length === 0 ? 'xx' : 'disabled'}`}
            onClick={() => ifFilters.length === 0 && setFiltersOpen(filtersOpen ? false : true)}
            onKeyDown={() => ifFilters.length === 0 && setFiltersOpen(filtersOpen ? false : true)}
            tabIndex="0"
            style={
              (state.activeFilters.length === 0 && filtersOpen && !cssDelay3) ?
              {
                position: 'relative',
              } : {}
            }
          >
            {ifFilters.length === 0 ? filtersOpen ? !cssDelay ? filterCloseLabel : attributesMenuLabel : !cssDelay ? attributesMenuLabel : filterCloseLabel : noFiltersLabel}
          </button>
          

          <Filters 
            resetFilterLabel={resetFilterLabel} 
            resetFilterActionLabel={resetFilterActionLabel} 
            orders={orders} 
            filtersOpen={filtersOpen}
            attributesMenuLabel={attributesMenuLabel}
            pageCounts={pageContext.counts}
            pageLoad={pageLoad}
            location={location}
          />
          

        </div>

      </div>}

    </>
  )

}
export default Sidebar