import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Link } from 'gatsby'


const SubmenuListMulti = ({ pagePath, sublist, parentPath, grandParentPath, pathMod, depth, pageLoad, sideNavUse, navId, showLessLabel, showMoreLabel }) => {


  // ROZPOZNAWANIE URL NA KTORYM JESTESMY I OTWIERANIE SUBNAVIGACJI
  const initButt = () => {
    return !sideNavUse ? false : sublist.nodes.some(x => `/${x.path}/` === `/${pagePath}`) || sublist.nodes.some(y => y.childItems?.nodes.some(z => `/${z.path}/` === `/${pagePath}`)) || `/${parentPath}/` === `/${pagePath}`
  }
  const [itemOpen, setItemOpen] = useState(() => initButt())


  useEffect(() => {
    setItemOpen(!sideNavUse ? false : sublist.nodes.some(x => `/${x.path}/` === `/${pagePath}`) || sublist.nodes.some(y => y.childItems?.nodes.some(z => `/${z.path}/` === `/${pagePath}`)) || `/${parentPath}/` === `/${pagePath}`)
  }, [sideNavUse])


  const[cssDelay, setcssDelay] = useState(false)
  useLayoutEffect(() => {
    
    setcssDelay(true)
    const timer = setTimeout(() => {
      setcssDelay(false)
    }, itemOpen ? 411 : 1);
    return () => clearTimeout(timer);
  }, [itemOpen])


  const [submenuShowItems, setSubmenuShowItems] = useState("")
  const toggleSubmenuItemsLenght = () => {
    if(submenuShowItems === "showLessItems") {
      setSubmenuShowItems("showMoreItems")
    }
    else {
      setSubmenuShowItems("showLessItems")
    }
  }
  
  return (
    <>

      <div 
        className={`submenuWrap ${itemOpen ? 'open' : 'closed'} ${submenuShowItems === "showLessItems" ? 'showLessItems' : 'showMoreItems' }`}
      >

        <button
          className={`subMenuButton menuButton ${itemOpen ? 'subMenuButtonOpen' : 'subMenuButtonClosed'}`}
          onClick={() => {setItemOpen(!itemOpen)}}
          onKeyDown={() => {setItemOpen(!itemOpen)}}
          tabIndex="0"
        >
          {itemOpen ? 
            <span style={{
              fontSize:'14px',
              color: '#f3cf45'
            }}>▲</span> 
            : 
            <span style={{
              fontSize:'14px',
              fontWeight:'bold',
              color: 'white'
              }} >▼</span>}
        </button>

        <ul 
          className={`subLevel subLevel-${depth} ${itemOpen ? 'open' : ''}`}
          style={
            itemOpen ?
            { 
              height: `${pageLoad ? 'auto' : cssDelay ? `${sublist.nodes.length * 36}px` : 'auto'}`,
              overflow: 'hidden'
            }
            :
            {
              height: `${pageLoad ? '1px' : cssDelay ? `${sublist.nodes.length * 36}px` : '1px'}`,
            }
          }
        >

          {sublist.nodes.length > 0 && sublist.nodes.map((item, key) => {

            return (
              <li 
                key={key} 
                className={`subLevelLink subLevelLink-${depth} menuLink ${ (`/${item.path}/` === `/${pagePath}` && sideNavUse) ? 'nav-bar_link--active' : ''} ${item.childItems?.nodes.length > 0 ? 'itemHaveChildren' : ''} ${cssDelay ? 'transition' : ''} ${pageLoad ? 'pageLoad' : ''}`}
                style={typeof item.order === 'number' ? {
                  order: `${item.order}`,
                } : {}}
              >
                <Link
                  to={pathMod === 'absolute' ? `/${item.path}/` : `${item.path}/`}
                  
                  activeClassName={`${sideNavUse ? 'nav-bar_link--active' : ''}`}
                  className={`${(navId === 'n2' && pagePath.includes(item.path)) ? ((sideNavUse || (sideNavUse && item.parentOf?.includes(pagePath))) ? 'nav-bar_link--active' : '') : ''}`}
                  partiallyActive={sideNavUse ? true : false}
                  itemProp="url"
                  state={{sideNavUseLoc: navId}}
                >
                  <span>{item.label}</span>

                </Link>
                {item.childItems?.nodes.length ?
                  <SubmenuListMulti
                    pagePath={pagePath}
                    sublist={item.childItems}
                    parentPath={item.path}
                    grandParentPath={parentPath}
                    pathMod={pathMod}
                    depth={depth + 1}
                    pageLoad={pageLoad}
                    sideNavUse={sideNavUse}
                    navId={navId}
                    showLessLabel={showLessLabel}
                    showMoreLabel={showMoreLabel}
                  />
                : ''}

              </li>
            )
          })}


          
          {sublist.nodes.length > 6 &&
            <button 
              onClick={() => toggleSubmenuItemsLenght()} 
              className={`catMenuButton sidebarButton`}
              style={itemOpen ? {order: '-1'} : {height: '1px', order: '-1'}}
            >
                {submenuShowItems === "showLessItems" ? showLessLabel : showMoreLabel}
            </button>
          }


        </ul>


      </div>
    </>
  )
}





const MenuListMulti = ({ pagePath, list, pathMod, pageLoad, sideNavUse, navId, showLessLabel, showMoreLabel  }) => {

  return (
    <ul 
    className={`topLevel`}      
    >

      {list && list.map((item, key) => {
        return (
          <li 
            key={key} 
            className={`topLevelLink menuLink ${(`/${item.path}/` === `/${pagePath}` && sideNavUse ) ? 'nav-bar_link--active' : ''} ${item.childItems.nodes.length > 0 ? 'itemHaveChildren' : ''}`}
            style={typeof item.order === 'number' ? {
              order: `${item.order}`,
              position: 'relative',
            } :{}}
          >
            <Link
              to={pathMod === 'absolute' ? `/${item.path}/` : `${item.path}/`}
              className={`${(navId === 'n3' && pagePath.includes(item.path)) ? ((sideNavUse || (sideNavUse && item.parentOf?.includes(pagePath))) ? 'nav-bar_link--active' : '') : (navId === 'n2' && pagePath.includes(item.path)) ? ((sideNavUse || (sideNavUse && item.parentOf?.includes(pagePath))) ? 'nav-bar_link--active' : '') : ''}`}
              activeClassName={`${sideNavUse ? 'nav-bar_link--active' : ''}`}
              partiallyActive={sideNavUse ? true : false}
              itemProp="url"
              state={{sideNavUseLoc: navId}}
            >
              <span>{item.label}</span>

              {(typeof item.order !== 'undefined' && navId === 'n3') && 
                <span 
                  className={`menuItemCount`}
                  style={{
                    position: 'absolute',
                    right: '40px',
                    fontWeight:'300',
                    fontSize: '.92em',
                    letterSpacing: '2.8px',
                    textDecoration:'none',
                  }}
                >
                  ({item.order})
                </span>
              }

            </Link>
            {item.childItems.nodes.length > 0 ?
              <SubmenuListMulti
                pagePath={pagePath}
                sublist={item.childItems}
                parentPath={item.path}
                grandParentPath={''}
                pathMod={pathMod}
                depth={2}
                pageLoad={pageLoad}
                sideNavUse={sideNavUse}
                navId={navId}
                showLessLabel={showLessLabel}
                showMoreLabel={showMoreLabel}
              />              
            : ''}
          </li>
        )
      })}
    </ul>
  )
}
export default MenuListMulti