import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Header from 'src/components/common/header.js'
import Sidebar from 'src/components/common/sidebar/sidebar'
import Layoutcore from 'src/components/layouts/layoutCore'
import { GatsbyImage } from "gatsby-plugin-image";
import { withPrefix } from "gatsby"


const CategoryLayout = ({ children, pageContext, page, location, searchIncArchive, isArchive, searchQString }) => {
  const graphqlResult = useStaticQuery(graphql`
    query CategoryLayoutQuery {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      langLabel: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "header-bar"}}) {
        acfheaderbar {
          languageSwitchLabel
        }
      }
      archivePageLabels: wpPage(AcfGatsbyId: {pageid: {eq: "archive"}}) {
        acfarchivepagelabels {
          exitFromArchive
        }
      }
      defaultPhone: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-phone"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
      defaultMail: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-mail"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
      siteSlogan: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "site-slogan"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      mobNavLabel: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "header-bar"}}) {
        acfheaderbar {
          navLabelShowAll
          mobileNav1Label
          mobileNav2Label
        }
      }
      burgerMenu: allWpMenu(filter: {name: {eq: "burgermenu"}}) {
        edges {
          node {
            slug
            name
            menuItems {
              nodes {
                label
                path
                id
                parentId
                cssClasses
                order
                childItems {
                  nodes {
                    label
                    path
                    id
                    parentId
                    cssClasses
                    order
                    childItems {
                      nodes {
                        label
                        path
                        id
                        parentId
                        cssClasses
                        order
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const customCrumbLabel = page.name
  if(page.baseName) {
    crumbs[1].crumbLabel = page.baseName
  }
  if(page.thirdName) {
    crumbs[2].crumbLabel = page.thirdName
  }

  const siteSlogan = graphqlResult.siteSlogan.acfgeneralinfos_layout.lineGenralInfosInfo

  const phoneHref = graphqlResult.defaultPhone.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHref2 = graphqlResult.defaultPhone.acfgeneralinfos_layout.line2GenralInfosInfo
  const phoneHrefClean = phoneHref.replace(/\s+/g, '')
  const phoneHrefClean2 = phoneHref2 ? phoneHref2.replace(/\s+/g, '') : ''

  const mailHref = graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo
  const mailHref2 = graphqlResult.defaultMail.acfgeneralinfos_layout.line2GenralInfosInfo

  const mobNavLabel = graphqlResult.mobNavLabel.acfheaderbar.navLabelShowAll
  const mobileNav1Label = graphqlResult.mobNavLabel.acfheaderbar.mobileNav1Label
  const mobileNav2Label = graphqlResult.mobNavLabel.acfheaderbar.mobileNav2Label

  const burgerMenu = graphqlResult.burgerMenu.edges[0].node.menuItems.nodes

  const typeMenu = pageContext.nav0

  const mainMenu = pageContext.nav1 
  const secondMenu = pageContext.nav2
  const secondMenuB = pageContext.nav2b
  const thirdMenu = pageContext.nav3

  const lang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo

  const exitFromArchive = graphqlResult.archivePageLabels ? graphqlResult.archivePageLabels.acfarchivepagelabels.exitFromArchive : null


  const pageTitle = pageContext.siteSlug === 'shared' ? page.title : page.name

  const featuredImage = pageContext.siteSlug === 'shared' ?
  {
    fluid: page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.featuredImage?.node?.alt || ``,
    title: page.featuredImage?.node.title || ``,
    description: page.featuredImage?.node.description || ``,
  }
  :
  {
    fluid: page.productcatacf?.categoryImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.productcatacf?.categoryImage?.altText || ``,
    title: page.productcatacf?.categoryImage?.title || ``,
    description: page.productcatacf?.categoryImage?.description || ``,
  }

  const initialWindoWidth = () => {
    return typeof document !== `undefined` ? document.body.offsetWidth : false
  }
  const[windowWidth, setWindowWidth] = useState(() => initialWindoWidth())
  const handleResize = () => {
    setWindowWidth(typeof document !== `undefined` ? document.body.offsetWidth : false)
  }
  // useLayoutEffect(() => {
  useEffect(() => {
    typeof window !== `undefined` && window.addEventListener('resize', handleResize, true)
    handleResize()
    return () => {
      typeof window !== `undefined` && window.removeEventListener('resize', handleResize, true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 

  const[mobile, setMobile] = useState() 
  useLayoutEffect(() => {
    setMobile(windowWidth > 764 ? false : true)
  }, [windowWidth])

  const[cssDelay, setcssDelay] = useState(false)
  useLayoutEffect(() => {
    setcssDelay(true)
    const timer = setTimeout(() => {
      setcssDelay(false)
    }, 333);
    return () => clearTimeout(timer);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 

  // ONLY true WHEN PAGE LOAD/CONTENT CHABGE - USE ONLY TO STOP SOME ANIMATIONS ON PAGE LOAD
  const[pageLoad, setpageLoad] = useState(false)
  useLayoutEffect(() => {
    setpageLoad(true)
    const timer = setTimeout(() => {
      setpageLoad(false)
    }, 933);
    return () => clearTimeout(timer);
  }, [location])


  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
        location: location,
        mobile: mobile,
    }),
  )


  const sideNavUseLoc = location?.state?.sideNavUseLoc


  return (
    <div
      className={`layoutWrap`}
    >

      <div className={`topMenu`}>

        <Header 
          pageContext={pageContext ? pageContext : false} 
          windowWidth={windowWidth} 
          headerMenu={burgerMenu} 

          typeMenu={typeMenu}
          secondMenuB={secondMenuB}           
          mobNavLabel={mobNavLabel}
          
          phoneHrefClean={phoneHrefClean}
          phoneHrefClean2={phoneHrefClean2}
          phoneHref={phoneHref}
          
          mailHref={mailHref}
          mailHref2={mailHref2}

          location={location}
          mobile={mobile}
          searchIncArchive={searchIncArchive}
          isArchive={isArchive}

          lang={lang}
          langLabel={graphqlResult.langLabel.acfheaderbar.languageSwitchLabel}

          mobileNav1Label={mobileNav1Label}
          mobileNav2Label={mobileNav2Label}

          siteSlogan={siteSlogan}

          searchQString={searchQString}
        />

      </div>

      
      <div className={`heading pageMaxWidth ${!mobile ? 'desktop' : 'mobile'}`} >

        {(page.content || featuredImage.fluid || pageTitle || page.description ) && 
          <div className={`pageContentContainer`} >

            {page.AcfGatsbyId.pageid === 'archive' ?
              <div className={`archiveTitleAndExitWrapper`}>
                <h1>{pageTitle}</h1>
                <a href={withPrefix('/')} className={`exitFromArchive`}><span className="signX">X</span> - {exitFromArchive}</a>
              </div>
            :
              <h1>{pageTitle}</h1>
            }
            
            {(page.AcfGatsbyId.pageid !== 'home') && <div className={`help-bar`} 
                style={
                  cssDelay ? 
                  {
                    opacity: '0'
                  } : 
                  {
                    opacity: '1'
                  } 
                }
              >

                <div
                  className={`crumbWrap ${!cssDelay ? '' : 'transition'}`}
                >        
                  <Breadcrumb
                    crumbs={crumbs}
                    crumbSeparator=""
                    crumbLabel={customCrumbLabel}
                  />
                </div>

              </div>
            }
            {featuredImage.fluid && 
            <div 
              style={{ 
                marginBottom: '20px', 
                marginTop: '20px', 
                maxWidth: '1320px', 
              }} 
              className="featuredCategoryImage"
            >
              <GatsbyImage
                image={featuredImage.gatsbyImageData}
                alt={featuredImage.alt}
                title={featuredImage.title}
                description={featuredImage.description} />
            </div>}

            {page.content && 
              <div 
                className={`wpContent`} 
                dangerouslySetInnerHTML={{ __html: page.content }}
              ></div>}

            {page.description && 
              <div 
                className={`wpContent`} 
                dangerouslySetInnerHTML={{ __html: page.description }}
              ></div>}

          </div>
        }
        
      </div>


      <div className={`pageMaxWidth ${!mobile ? 'desktop' : 'mobile'}`} >

        {pageContext.sidebar && 
          <Sidebar 
            mobile={mobile} 
            mainMenu={mainMenu} 
            secondMenu={secondMenu} 
            thirdMenu={thirdMenu} 
            pageContext={pageContext}

            pageLoad={pageLoad}

            sideNavUseLoc={sideNavUseLoc}
            isArchive={isArchive}
            archiveSidebar={pageContext.archiveSidebar}

            location={location}
          />
        }

        <div
          className={`pageContent ${isArchive ? 'archive' : ''}`}  
          style={
            pageContext.sidebar ?
            (!mobile ?
              {
                width: 'calc(100% - 280px)'
              }
              :
              {
                width: '100%'
              }              
            ) :
            {
              width: '100%'
            }
          }
        >

          <Layoutcore 
            pageContext={pageContext} 
            location={location} 
            searchIncArchive={searchIncArchive} 
            isArchive={isArchive} 
          >
            {childrenWithProps}
          </Layoutcore>

        </div>

      </div>

    </div>
  )
}
export default CategoryLayout